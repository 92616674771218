/**
 * 文档 板卡状态
 * @param status
 * @returns {string}
 */
export function getInfoStatus(status) {
    if (typeof status === "string") {
        status = parseInt(status);
    }
    switch (status) {
        case -2:
            return "由板卡创建";
        case -1:
            return "已取消";
        case 0:
            return "初稿";
        case 1:
            return "正在工作";
        case 2:
            return "审阅中";
        case 3:
            return "返回修改";
        case 4:
            return "申请中";
        case 9:
            return "已发布";
        case 10:
            return "禁用";
        case 11:
            return "废弃";
        case 12:
            return "设计";
        case 13:
            return "预发布";
        case 14:
            return "认证中";
        case 15:
            return "已认证";
        case 16:
            return "已批准";
        case 17:
            return "工程发布";
        default:
            return "暂无";
    }
}

/**
 * PLM状态
 * @param status
 * @returns {string}
 */
export function getPLMStatusMeth(status) {
    if (typeof status === "string") {
        status = parseInt(status);
    }
    switch (status) {
        case 13:
            return "预发布";
        case 9:
            return "已发布";
        case 17:
            return "工程发布";
        case 10:
            return "禁用";
        case 11:
            return "废弃";
        default:
            return "";
    }
}
